import React from 'react';
import styles from './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import Home from './components/Home';
import Nav2 from './components/UI/NavbarBottom.js';

const About = lazy(() => import('./components/About.js'));
const Checkoutlong = lazy(() => import('./components/Checkout/Checkoutlong.js'));
const Navbar = lazy(() => import('./components/UI/Navbar'));
const Product = lazy(() => import('./components/products/Product.js'));
const Footer = lazy(() => import('./components/Footer'));
const FaqsPage = lazy(() => import('./components/UI/FaqsPage'));
const Pets = lazy(() => import('./components/Pets'));
const Printing = lazy(() => import('./components/misc/Printing'));
const Photographers = lazy(() => import('./components/misc/Photographers'));
const SinglePost = lazy(() => import('./components/Blog/SinglePost'));
const Blog = lazy(() => import('./components/Blog/Blog'));
const Affiliate = lazy(() => import('./components/Affiliate'));
const Terms = lazy(() => import('./components/Terms.js'));
const Privacy = lazy(() => import('./components/Privacy.js'));
const Support = lazy(() => import('./components/Support.js'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const PetImages = lazy(() => import('./components/PetImages'));
const Account = lazy(() => import('./components/Account/Account'));
// const Gallery = lazy(() => import('./components/Gallery'));
const AffTerms = lazy(() => import('./components/Affiliate/AffTerms'));
const NewBlog = lazy(() => import('./components/Blog/NewBlog.js'));
// const UseCases = lazy(() => import('./components/UI/UseCases.js'));
const CatNames = lazy(() => import('./components/misc/CatNames.js'));
const GiftOrder = lazy(() => import('./components/products/GiftOrder.js'));

const OrderManager = lazy(() => import('./components/Account/OrderManager.js'))
const Voucher = lazy(() => import('./components/products/VoucherProd.js'));
const News = lazy(() => import('./components/UI/News'));

const Chat = lazy(() => import('./components/UI/Chat.js'))
// const PhotoEdit = lazy(() => import('./components/UI/PhotoEdit.js'))
// const Products = lazy(() => import('./components/products/Products.js'));
// const NewReview = lazy(() => import('./components/Tools/NewReview.js'));
// const CatNamesGen = lazy(() => import('./components/misc/CatNamesGen.js'));
// const AffControl = lazy(() => import('./components/Tools/AffControl'));
// const CompleteOrder = lazy(() => import('./components/CompleteOrder'));


function App() {
  return (
    <div className='App'>

      <Router>

        <header>
          <News />
          <Navbar />
          {/* <NewsTop /> */}
        </header>

        <div className={styles.content}>
          <Suspense fallback={<div style={{ height: '100vh' }}>Loading Page Component...</div>}>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/orders" element={<Pets />} />
              <Route path="/pet-images/:petModelId" element={<PetImages />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/myprofile" element={<Account />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="/support" element={<Support />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog/:pageNumber" element={<Blog />} />
              <Route path="/blog" element={<Navigate replace to="/blog/1" />} />
              <Route path="/guide" element={<Navigate replace to="/" />} />

              {/* <Route path="/pet-portraits-gallery/:pageNumber" element={<Gallery />} /> */}
              <Route path="/affiliate-terms" element={<AffTerms />} />
              <Route path="/blogs/:postid" element={<SinglePost />} />
              <Route path="/faqs" element={<FaqsPage />} />
              {/* <Route path="/pet-portrait-images-use-cases" element={<UseCases />} /> */}
              <Route path="/newblog" element={<NewBlog />} />
              <Route path="/printing" element={<Printing />} />
              <Route path="/photographers" element={<Photographers />} />
              <Route path="/ordrmng" element={<OrderManager />} />
              <Route path="/product/:productName" element={<Product />} />
              <Route path="/cat-names" element={<CatNames />} />
              <Route path="/pet-gift-portraits-checkout" element={<GiftOrder />} />
              <Route path="/order" element={<Checkoutlong />} />
              <Route path="/pet-gift-voucher-portraits" element={<Voucher />} />
              <Route path="/pet-portraits-gallery/:pageNumber" element={<Navigate replace to="/" />} />

              {/* <Route path="/cat-name-generator" element={<CatNamesGen />} /> */}
              {/* <Route path="/cat-name-generator" element={<ExternalRedirect to="https://aicatz.com" />} /> */}
              {/* <Route path="/order" element={<CompleteOrder />} /> */}
              {/* <Route path="/affcontroll" element={<AffControl />} /> */}
              {/* <Route path="/leave-a-review" element={<NewReview />} /> */}
              <Route path="/chat" element={<Chat />} />
              {/* <Route path="/pe" element={<PhotoEdit />} /> */}
              {/* <Route path="/productlist/" element={<Products />} /> */}
              {/* <Route path="/subscribe" element={<Subscribe />} /> */}

            </Routes>
          </Suspense>
        </div>

        <Nav2 />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
