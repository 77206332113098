import React from 'react'
import styles from './Bundles.module.css'
import { NavLink } from "react-router-dom"
import arrowIcon from "../../Assets/icons/next-2.png";
import tick from "../../Assets/icons/tickbox.webp";

export default function Bundles({ voucher, hidden }) {
  if (hidden) return;

  if (voucher) {
    return (
      <div className={styles.bundleWrapVoucher} >
        <h4 className={styles.h4}>
          <p>Gift Voucher Bundle</p></h4>

        <div className={styles.priceButton}>
          <div className={styles.itemsGroup}>
            <div className={styles.item}>
              All 21 Characters</div>
          </div>
          <div className={styles.includedMob}>
            <div className={styles.itemSmall}>
              <img className={styles.icon1} src={tick} alt="tick icon" />
              4K Resolution</div>
            <div className={styles.itemSmall}>
              <img className={styles.icon1} src={tick} alt="tick icon" />
              Auto-Generated</div>
            <div className={styles.itemSmall}>
              <img className={styles.icon1} src={tick} alt="tick icon" />
              Valid for 18 months</div>
            <div className={styles.itemSmall}>
              <img className={styles.icon1} src={tick} alt="tick icon" />
              Valid for 1 Pet - Cat or Dog</div>

            <div className={styles.priceDiv}>
              <p className={styles.promoPrice}>
                <b>$10</b><sup
                  className={styles.ppSup}
                >.99</sup>
                <small
                  className={styles.perPetUSD}
                > / pet in USD</small>
              </p>
            </div>

            <NavLink to="/pet-gift-portraits-checkout"
              className={styles.navlinkStarted}
              state={{ product: "Gift_voucher" }} >
              <button className={styles.getStarted}
              // disabled={true}
              >
                Get Gift Voucher
                <img className={styles.icon} src={arrowIcon} alt="tick icon" />
              </button>
            </NavLink>

          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.serviceSpanEx} >
      <div className={styles.priceGroup}>

        <div className={styles.bundleWrap}>
          <h4 className={styles.h4}>
            <p className={styles.originalPrice}>New</p>
            <p>Starter Bundle</p></h4>

          <div className={styles.priceButton}>
            <div className={styles.itemsGroup}>
              <p className={styles.item}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                6 Random Characters
              </p>
            </div>
            <div className={styles.includedMob}>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                4K Resolution</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Auto-Generated</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Delivered in 17 mins</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                +5 Manual Images</div>
            </div>
            <div className={styles.priceDiv}>

              <p className={styles.promoPrice}>
                <b>$3</b><sup
                  className={styles.ppSup}
                >.99</sup>
                <small
                  className={styles.perPetUSD}
                > / pet in USD</small>
              </p>
            </div>

            <NavLink to="/order" className={styles.navlinkStarted}
              state={{ product: "Bundle_6" }}>
              <button className={styles.getStarted}>
                Get Images
                <img className={styles.icon} src={arrowIcon} alt="tick icon" />
              </button>
            </NavLink>

          </div>
        </div>




        <div className={styles.bundleWrap} >

          <h4 className={styles.h4}><p className={styles.originalPrice}>Save 12%</p>
            <p>Saver Bundle</p></h4>
          <div className={styles.priceButton}>
            <div className={styles.itemsGroup}>
              <p className={styles.item}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                12 Random Characters
              </p>
            </div>

            <div className={styles.includedMob}>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                4K Resolution</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Auto-Generated</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Delivered in 17 mins</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                +5 Manual Images</div>
            </div>
            <div className={styles.priceDiv}>
              <p className={styles.promoPrice}>
                <b>$6</b><sup
                  className={styles.ppSup}
                >.99</sup>
                <small
                  className={styles.perPetUSD}
                > / pet in USD</small>
              </p>
            </div>

            <NavLink to="/order" className={styles.navlinkStarted} state={{ product: "Bundle_5" }} >
              <button className={styles.getStarted}>
                Get Images
                <img className={styles.icon} src={arrowIcon} alt="tick icon" />
              </button>
            </NavLink>


          </div>
        </div>

        <div className={styles.bundleWrap} >
          <h4 className={styles.h4}>
            <p className={styles.originalPrice}>
              Save 21%
            </p><p>Best Price Bundle</p></h4>

          <div className={styles.priceButton}>
            <div className={styles.itemsGroup}>
              <p className={styles.item}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                All 21 Characters
              </p>
            </div>

            <div className={styles.includedMob}>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                4K Resolution</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Auto-Generated</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                Delivered in 17 mins</div>
              <div className={styles.itemSmall}>
                <img className={styles.icon1} src={tick} alt="tick icon" />
                +5 Manual Images</div>
            </div>

            <div className={styles.priceDiv}>
              <p className={styles.promoPrice}>
                <b>$10</b><sup
                  className={styles.ppSup}
                >.99</sup>
                <small
                  className={styles.perPetUSD}
                > / pet in USD</small>
              </p>
            </div>

            <NavLink to="/order" className={styles.navlinkStarted} state={{ product: "Bundle_4" }} >
              <button className={styles.getStarted}>
                Get Images
                <img className={styles.icon} src={arrowIcon} alt="tick icon" />


              </button>
            </NavLink>

          </div>
        </div>


      </div>
      {/* </div > */}

      {/* end */}
      {/* <p><strong>NEW Bonus Feature:</strong>Place your pet into any photo!</p> */}

    </div >


  )
}