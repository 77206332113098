import React from 'react';
import faqs from '../Data/faqs';
import styles from './Faqs.module.css';


export default function Faqs() {
  const [activeIndex, setActiveIndex] = React.useState(null);

  function handleActiveIndex(index) {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  }

  return (
    <div className={styles.faqContainer}>


      <h2 className={styles.faqHeading}>FAQs</h2>
      <div className={styles.faqWrapper}>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={styles.faqItemIn}>

            <h3 className={styles.faqQuestion}>{faq.question}</h3>

            {/* <small>{activeIndex !== index ? "TAP TO EXPAND" : "TAP TO COLLAPSE"}</small> */}
            <p className={styles.faqAnswer}>
              {faq.answer}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
